@import "../styles/variables";
@import "../styles/mixins";

.box {
  width: 100%;
  height: 120px;
  // background-color: red;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  display: flex;
  flex-direction: row;
  // justify-content: space-between;
  // width: 975px;
}

.text {
  font-family: Raleway;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 2.4px;
  color: $color-grey;
  text-align: center;

  @include xx-small-screen {
    font-size: 16px;
  }
}

.letter {
  margin-right: 0.5px;
}

.color__change {
  animation: colorChange 1250ms forwards;
}

.element__hidden {
  display: none;
}

@keyframes colorChange {
  0%,
  100% {
    color: $color-grey;
  }

  50% {
    color: $color-neon;
  }
}

.title {
  @include small-screen {
    width: 280px;
  }

  @include xx-small-screen {
    width: 200px;
  }
}

.subtitle {
  @include small-screen {
    font-size: 20px;
  }
  @include xx-small-screen {
    font-size: 14px;
  }
}
