@import "../../styles/variables";
@import "../../styles/mixins";
.content {
  position: relative;
  height: 2070px;
  display: flex;
  justify-content: center;
  // background-color: blue;
  margin-top: -100px;

  @include x-large-screen {
    height: 1670px;
    margin-top: -120px;
  }

  @include large-screen {
    height: 1010px;
    margin-top: -160px;
  }
}

.portfolio {
  margin-top: 0px;
  // background-color: red;
  width: 976px;

  @include x-large-screen {
    // background-color: blue;
    width: 780px;
  }

  @include large-screen {
    // background-color: green;
    width: 457px;
  }
}

.row {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 20px 0 10px 0;
}

$box-transition: all 300ms linear;

%box-small {
  position: absolute;
  height: 314px;
  width: 314px;
  transition: $box-transition;
  z-index: 1;

  @include x-large-screen {
    height: 251px;
    width: 251px;
  }

  @include large-screen {
    height: 147px;
    width: 147px;
  }
}

$multiplier: 0.743;
$multiplier-x-large: 0.594;
$multiplier-large: 0.3474;

.box1 {
  @extend %box-small;
  left: 0;
  top: 0;

  &:hover {
    @include box-hover(869px, 574px, $multiplier);

    @include x-large-screen {
      @include box-hover(869px, 574px, $multiplier-x-large);
    }

    @include large-screen {
      @include box-hover(869px, 574px, $multiplier-large);
    }
  }
}

.box2 {
  @extend %box-small;
  @include center-content(x);
  top: 0;

  &:hover {
    @include box-hover(574px, 869px, $multiplier);

    @include x-large-screen {
      @include box-hover(574px, 869px, $multiplier-x-large);
    }

    @include large-screen {
      @include box-hover(574px, 869px, $multiplier-large);
    }
  }
}

.box3 {
  @extend %box-small;
  right: 0;
  top: 0;

  &:hover {
    @include box-hover(1038px, 422px, $multiplier);

    @include x-large-screen {
      @include box-hover(1038px, 422px, $multiplier-x-large);
    }

    @include large-screen {
      @include box-hover(1038px, 422px, $multiplier-large);
    }
  }
}

$second-row-top: 310px;
$second-row-top-x-large: 244px;
$second-row-top-large: 135px;

.box4 {
  @extend %box-small;
  left: 0;
  top: $second-row-top;

  &:hover {
    top: 140px;
    @include box-hover(617px, 869px, $multiplier);

    @include x-large-screen {
      top: 117px;
      @include box-hover(617px, 869px, $multiplier-x-large);
    }
    @include large-screen {
      top: 60px;
      @include box-hover(617px, 869px, $multiplier-large);
    }
  }

  @include x-large-screen {
    top: $second-row-top-x-large;
  }
  @include large-screen {
    top: $second-row-top-large;
  }
}

.box5 {
  @extend %box-small;
  @include center-content(x);
  top: $second-row-top;

  &:hover {
    top: 230px;
    @include box-hover(869px, 634px, $multiplier);

    @include x-large-screen {
      top: 182px;
      @include box-hover(869px, 634px, $multiplier-x-large);
    }
    @include large-screen {
      top: 98px;
      @include box-hover(869px, 634px, $multiplier-large);
    }
  }

  @include x-large-screen {
    top: $second-row-top-x-large;
  }
  @include large-screen {
    top: $second-row-top-large;
  }
}

.box6 {
  @extend %box-small;
  right: 0;
  top: $second-row-top;

  &:hover {
    top: 135px;
    @include box-hover(921px, 869px, $multiplier);

    @include x-large-screen {
      top: 110px;
      @include box-hover(921px, 869px, $multiplier-x-large);
    }
    @include large-screen {
      top: 56px;
      @include box-hover(921px, 869px, $multiplier-large);
    }
  }

  @include x-large-screen {
    top: $second-row-top-x-large;
  }
  @include large-screen {
    top: $second-row-top-large;
  }
}

$third-row-top: 620px;
$third-row-top-x-large: 488px;
$third-row-top-large: 270px;

.box7 {
  @extend %box-small;
  left: 0;
  top: $third-row-top;

  &:hover {
    top: 597px;
    @include box-hover(869px, 485px, $multiplier);

    @include x-large-screen {
      top: 468px;
      @include box-hover(869px, 485px, $multiplier-x-large);
    }
    @include large-screen {
      top: 258px;
      @include box-hover(869px, 485px, $multiplier-large);
    }
  }

  @include x-large-screen {
    top: $third-row-top-x-large;
  }
  @include large-screen {
    top: $third-row-top-large;
  }
}

.box8 {
  @extend %box-small;
  @include center-content(x);
  top: $third-row-top;

  &:hover {
    top: 560px;
    @include box-hover(869px, 581px, $multiplier);

    @include x-large-screen {
      top: 440px;
      @include box-hover(869px, 581px, $multiplier-x-large);
    }
    @include large-screen {
      top: 242px;
      @include box-hover(869px, 581px, $multiplier-large);
    }
  }

  @include x-large-screen {
    top: $third-row-top-x-large;
  }
  @include large-screen {
    top: $third-row-top-large;
  }
}

.box9 {
  @extend %box-small;
  right: 0;
  top: $third-row-top;

  &:hover {
    top: 570px;
    @include box-hover(869px, 581px, $multiplier);

    @include x-large-screen {
      top: 440px;
      @include box-hover(869px, 581px, $multiplier-x-large);
    }
    @include large-screen {
      top: 242px;
      @include box-hover(869px, 581px, $multiplier-large);
    }
  }

  @include x-large-screen {
    top: $third-row-top-x-large;
  }
  @include large-screen {
    top: $third-row-top-large;
  }
}

$fourth-row-top: 930px;
$fourth-row-top-x-large: 732px;
$fourth-row-top-large: 405px;

.box10 {
  @extend %box-small;
  left: 0;
  top: $fourth-row-top;

  &:hover {
    top: 903px;
    @include box-hover(869px, 493px, $multiplier);

    @include x-large-screen {
      top: 712px;
      @include box-hover(869px, 493px, $multiplier-x-large);
    }
    @include large-screen {
      top: 394px;
      @include box-hover(869px, 493px, $multiplier-large);
    }
  }

  @include x-large-screen {
    top: $fourth-row-top-x-large;
  }
  @include large-screen {
    top: $fourth-row-top-large;
  }
}

.box11 {
  @extend %box-small;
  @include center-content(x);
  top: $fourth-row-top;

  &:hover {
    top: 870px;
    @include box-hover(869px, 579px, $multiplier);

    @include x-large-screen {
      top: 688px;
      @include box-hover(869px, 579px, $multiplier-x-large);
    }
    @include large-screen {
      top: 378px;
      @include box-hover(869px, 579px, $multiplier-large);
    }
  }

  @include x-large-screen {
    top: $fourth-row-top-x-large;
  }
  @include large-screen {
    top: $fourth-row-top-large;
  }
}

.box12 {
  @extend %box-small;
  right: 0;
  top: $fourth-row-top;

  &:hover {
    top: 830px;
    @include box-hover(869px, 694px, $multiplier);

    @include x-large-screen {
      top: 648px;
      @include box-hover(869px, 694px, $multiplier-x-large);
    }
    @include large-screen {
      top: 354px;
      @include box-hover(869px, 694px, $multiplier-large);
    }
  }

  @include x-large-screen {
    top: $fourth-row-top-x-large;
  }
  @include large-screen {
    top: $fourth-row-top-large;
  }
}

$fifth-row-top: 1240px;
$fifth-row-top-x-large: 976px;
$fifth-row-top-large: 540px;

.box13 {
  @extend %box-small;
  left: 0;
  top: $fifth-row-top;

  &:hover {
    top: 1237px;
    @include box-hover(1034px, 433px, $multiplier);

    @include x-large-screen {
      top: 973px;
      @include box-hover(1034px, 433px, $multiplier-x-large);
    }
    @include large-screen {
      top: 538px;
      @include box-hover(1034px, 433px, $multiplier-large);
    }
  }

  @include x-large-screen {
    top: $fifth-row-top-x-large;
  }
  @include large-screen {
    top: $fifth-row-top-large;
  }
}

.box14 {
  @extend %box-small;
  @include center-content(x);
  top: $fifth-row-top;

  &:hover {
    top: 1180px;
    @include box-hover(1013px, 581px, $multiplier);

    @include x-large-screen {
      top: 928px;
      @include box-hover(1013px, 581px, $multiplier-x-large);
    }
    @include large-screen {
      top: 512px;
      @include box-hover(1013px, 581px, $multiplier-large);
    }
  }

  @include x-large-screen {
    top: $fifth-row-top-x-large;
  }
  @include large-screen {
    top: $fifth-row-top-large;
  }
}

.box15 {
  @extend %box-small;
  right: 0;
  top: $fifth-row-top;

  &:hover {
    top: 1180px;
    @include box-hover(869px, 581px, $multiplier);

    @include x-large-screen {
      top: 928px;
      @include box-hover(869px, 581px, $multiplier-x-large);
    }
    @include large-screen {
      top: 510px;
      @include box-hover(869px, 581px, $multiplier-large);
    }
  }

  @include x-large-screen {
    top: $fifth-row-top-x-large;
  }
  @include large-screen {
    top: $fifth-row-top-large;
  }
}

$sixth-row-top: 1550px;
$sixth-row-top-x-large: 1220px;
$sixth-row-top-large: 675px;

.box16 {
  @extend %box-small;
  left: 0;
  top: $sixth-row-top;

  &:hover {
    top: 1386px;
    @include box-hover(869px, 646px, $multiplier);

    @include x-large-screen {
      top: 1088px;
      @include box-hover(869px, 646px, $multiplier-x-large);
    }
    @include large-screen {
      top: 598px;
      @include box-hover(869px, 646px, $multiplier-large);
    }
  }

  @include x-large-screen {
    top: $sixth-row-top-x-large;
  }
  @include large-screen {
    top: $sixth-row-top-large;
  }
}

.box17 {
  @extend %box-small;
  @include center-content(x);
  top: $sixth-row-top;

  &:hover {
    top: 1411px;
    @include box-hover(913px, 609px, $multiplier);

    @include x-large-screen {
      top: 1110px;
      @include box-hover(913px, 609px, $multiplier-x-large);
    }
    @include large-screen {
      top: 611px;
      @include box-hover(913px, 609px, $multiplier-large);
    }
  }

  @include x-large-screen {
    top: $sixth-row-top-x-large;
  }
  @include large-screen {
    top: $sixth-row-top-large;
  }
}

.box18 {
  @extend %box-small;
  right: 0;
  top: $sixth-row-top;

  &:hover {
    top: 1504px;
    @include box-hover(869px, 488px, $multiplier);

    @include x-large-screen {
      top: 1182px;
      @include box-hover(869px, 488px, $multiplier-x-large);
    }
    @include large-screen {
      top: 653px;
      @include box-hover(869px, 488px, $multiplier-large);
    }
  }

  @include x-large-screen {
    top: $sixth-row-top-x-large;
  }
  @include large-screen {
    top: $sixth-row-top-large;
  }
}

.visible__area {
  width: 100%;
  overflow: hidden;
  margin-top: -70px;

  @include small-screen {
    margin-top: 0px;
  }
}

.text__content {
  width: 1900px;
  height: 60px;
  overflow: hidden;
}

.text {
  display: flex;
  flex-direction: row;
  animation: scrollText 15s linear infinite;

  font-size: 24px;
  font-family: "Raleway";
  font-weight: 400;
  color: rgba(195, 212, 0, 1);
  letter-spacing: 2px;

  @include x-large-screen {
    font-size: 19px;
  }

  @include large-screen {
    font-size: 12px;
  }
}

.text p {
  margin-right: 100px;
}

@keyframes scrollText {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-95%);
  }
}

.mobile__section {
  width: 100%;
  overflow-x: auto;
}

.mobile__portfolio {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.image {
  height: 100%;
  @include x-small-screen {
    height: 90%;
  }
  @include xx-small-screen {
    height: 80%;
  }
}

.decription__mobile {
  margin-top: 20px;
}

.title {
  @include small-screen {
    width: 230px;
  }
  @include x-small-screen {
    width: 220px;
  }
  @include xx-small-screen {
    width: 170px;
  }
}

.subtitle {
  @include small-screen {
    font-size: 20px;
  }
  @include xx-small-screen {
    font-size: 14px;
  }
}

.text__mobile {
  padding-top: 50px;
  margin-bottom: 50px;
  @include text-mobile;

  @include xx-small-screen {
    padding-top: 30px;
    margin-bottom: 30px;
  }
}

.img__style {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
