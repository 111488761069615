@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import "variables";
@import "./mixins";
body {
  color: $color-grey;
  background-color: $backgroun-color;
  font-family: "Raleway", sans-serif;
}

h1,
h2,
h3,
h4,
h5 {
  color: $color-grey;
  font-style: normal;
  font-weight: 300;
}

h1 {
  font-size: 55px;
}

h2 {
  font-size: 40px;
  text-align: center;
}

h3 {
  font-size: 36px;
  line-height: 34px;

  @include x-large-screen {
    font-size: 30px;
    line-height: 28px;
  }
}

h4 {
  font-size: 24px;
  line-height: 24px;
}

.title__green,
.title__green__small {
  color: $color-neon;

  font-style: normal;
  font-weight: 900;
  line-height: 34px; /* 85% */
  letter-spacing: 4px;
  text-transform: uppercase;

  font-size: 40px;
  &__small {
    font-size: 33px;
    line-height: 38px;
  }

  @include x-large-screen {
    font-size: 32px;
    line-height: 27.2px;

    &__small {
      font-size: 26px;
      line-height: 30px;
      letter-spacing: 3px;
    }
  }

  @include large-screen {
    font-size: 22.4px;
    line-height: 19px;

    &__small {
      font-size: 15.5px;
      line-height: 18.5px;
      letter-spacing: 2.5px;
    }
  }

  @include medium-screen {
    font-size: 16px;
    line-height: 12px;
    &__small {
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 1.5px;
    }
  }

  @include small-screen {
    font-size: 20px;
    line-height: 20px;
  }

  @include x-small-screen {
    font-size: 18px;
    line-height: 18px;
  }

  @include xx-small-screen {
    font-size: 16px;
    line-height: 16px;
  }
}

.titles,
.titles__small {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 15px;
  &__small {
    gap: 10px;
  }

  > h1,
  h2 {
    margin: 0;
  }

  > h3 {
    margin: 0;
    padding-bottom: 15px;
  }

  > p {
    margin: 0;
    &__small {
      padding-bottom: 10px;
    }
  }
}

.titles__mobile,
.titles__mobile__small {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  // background-color: red;

  gap: 15px;
  &__small {
    gap: 10px;
  }

  > h1,
  h2 {
    margin: 0;
  }

  > h3 {
    margin: 0;
    padding-bottom: 15px;
  }

  > p {
    margin: 0;
    &__small {
      padding-bottom: 10px;
    }
  }

  @include x-small-screen {
    gap: 12px;
    &__small {
      gap: 8.5px;
    }

    > p {
      margin: 0;
      &__small {
        padding-bottom: 7.5px;
      }
    }

    > p {
      margin: 0;
      &__small {
        padding-bottom: 5px;
      }
    }
  }

  @include xx-small-screen {
    gap: 8px;
    &__small {
      gap: 5px;
    }
  }
}

%title-mobile-green-style {
  color: $color-neon;
  color: white;
  font-style: normal;
  font-weight: 900;
  line-height: 20px;
  letter-spacing: 4px;
  text-transform: uppercase;
  background-color: green;
}

.title__mobile__green {
  @extend %title-mobile-green-style;

  font-size: 20px;
}

title__mobile__green__small {
  @extend %title-mobile-green-style;
  font-size: 15px;
  @include x-small-screen {
    font-size: 12px;
  }
}

section {
  width: 100%;
}

.section__content {
  // width: 68%;
  // padding-left: 16%;
  // padding-right: 16%;
  position: relative;
  width: 1200px;
  left: 50%;
  transform: translate(-50%);

  @include small-screen {
    width: 100%;
  }
}

.text {
  color: $color-grey;
  font-family: "Raleway", sans-serif;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;

  @include x-large-screen {
    font-size: 16px;
  }

  @include large-screen {
    font-size: 11px;
  }

  @include medium-screen {
    font-size: 9px;
  }
}

@include x-large-screen {
  h1 {
    font-size: 44px;
    line-height: 40px;
  }
  h2 {
    font-size: 30px;
    line-height: 28px;
  }
}
@include large-screen {
  h1 {
    font-size: 31px;
    line-height: 28px;
  }
  h2 {
    font-size: 17.8px;
    line-height: 16px;
  }
}
@include medium-screen {
  h1 {
    font-size: 26px;
    line-height: 23px;
  }
  h2 {
    font-size: 14px;
    line-height: 13px;
  }
}

@include small-screen {
  h1 {
    font-size: 35px;
  }

  h2 {
    font-size: 35px;
    line-height: 38px;
  }

  .title__mobile__green,
  .title__mobile__green__small {
    color: $color-neon;

    font-style: normal;
    font-weight: 900;
    line-height: 20px; /* 85% */
    letter-spacing: 4px;
    text-transform: uppercase;

    font-size: 20px;
    &__small {
      font-size: 20px;
    }
  }
}

@include x-small-screen {
  h1 {
    font-size: 32px;
  }
  h2 {
    font-size: 35px;
    line-height: 38px;
  }
}

@include xx-small-screen {
  h1 {
    font-size: 28px;
  }
  h2 {
    font-size: 26px;
    line-height: 29px;
  }
}
