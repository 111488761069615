@import "../../styles/variables";
@import "../../styles/mixins";

%footer-style {
  width: 100%;
  display: flex;
  align-items: end;
  justify-content: center;
  // background-color: red;
  position: absolute;
  z-index: 100;
}

.footer___without__email {
  @extend %footer-style;
  height: 470px;

  @include x-small-screen {
    height: 450px;
  }
}

.footer__email {
  @extend %footer-style;
  height: 615px;

  @include x-small-screen {
    height: 560px;
  }

  @include xx-small-screen {
    height: 520px;
  }
}

.box {
  height: 431px;
  width: 84%;
  opacity: 0.5;
  background: $header-color;
  box-shadow: -52.719px 0px 55.355px 0px rgba(0, 0, 0, 0.1) inset,
    -15.816px 0px 47.447px 5.272px rgba(0, 0, 0, 0.41) inset,
    -26.359px 26.359px 39.539px 0px rgba(0, 0, 0, 0.25),
    26.359px -26.359px 39.539px 0px #fff;

  transition: all 500ms linear;
  height: 100%;
}

.content {
  position: absolute;
  height: 500px;
  width: 75%;
  margin-bottom: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-clip: padding-box;
  height: 100%;
}

.titles {
  margin-top: 20px;

  @include x-small-screen {
    margin-top: 15px;
  }

  @include xx-small-screen {
    margin-top: 10px;
  }
}

.title {
  width: 300px;

  @include small-screen {
    width: 231px;
  }
  @include x-small-screen {
    width: 220px;
  }
  @include xx-small-screen {
    width: 180px;
  }
}

.social__media {
  width: 98%;
  // background-color: yellow;
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 50px;

  @include x-large-screen {
    margin-top: 40px;
  }
}

.social_button {
  @include small-screen {
    width: 58px;
    height: 58px;
    // background-color: red;
  }
  @include x-small-screen {
    width: 50px;
    height: 50px;
  }
  @include xx-small-screen {
    width: 42px;
    height: 42px;
  }
}

.logo {
  position: absolute;
  transition: transform 500ms linear;
  bottom: 60px;

  @include x-small-screen {
    bottom: 55px;
    transform: scale(0.85);
  }
  @include xx-small-screen {
    bottom: 50px;
    transform: scale(0.75);
  }
}

.rotated {
  transform: rotate(180deg);

  @include x-small-screen {
    transform: rotate(180deg) scale(0.85);
  }
  @include xx-small-screen {
    transform: rotate(180deg) scale(0.75);
  }
}

.small__text {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  bottom: 10px;

  @include x-small-screen {
    bottom: 8px;
    transform: scale(0.85);
  }

  @include xx-small-screen {
    bottom: 6px;
    transform: scale(0.8);
  }
}

.small__text > p {
  margin: 0;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;

  @include xx-small-screen {
    font-size: 10px;
  }
}

.email {
  width: 98%;
  margin-left: 15%;
  margin-right: 15%;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  opacity: 0;
  transition: opacity 500ms ease;

  @include x-small-screen {
    margin-top: 20px;
  }

  @include xx-small-screen {
    margin-top: 10px;
  }
}

.visibleDiv {
  opacity: 1;
}

.field {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  width: 100.3%;
  // background-color: red;
}

.label {
  color: $color-grey;
  text-align: center;
  font-family: Raleway;
  font-size: px;
  font-style: normal;
  font-weight: 500;
  line-height: 35px;
  width: 100px;
  text-align: start;

  @include x-small-screen {
    font-size: 15px;
    line-height: 32px;
  }

  @include xx-small-screen {
    font-size: 14px;
    line-height: 30px;
  }
}

.input__style {
  border-width: 0px;
  text-align: start;
  padding-left: 3%;
  font-size: 14px;
  width: 100%;

  @include x-small-screen {
    font-size: 12.7px;
  }

  @include xx-small-screen {
    font-size: 11px;
  }
}

.text__area {
  margin-top: 10px;
  width: 100%;
  border-width: 0px;
  height: 100px;
  text-align: left;
  resize: none;
  overflow-y: auto;
  font-size: 12px;
  padding-left: 3%;

  @include x-small-screen {
    font-size: 11.5px;
    height: 90px;
  }

  @include xx-small-screen {
    font-size: 11px;
    height: 80px;
  }
}

.buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 25px;

  @include x-small-screen {
    margin-top: 20px;
  }

  @include xx-small-screen {
    margin-top: 15px;
  }
}

%button {
  width: 200px;
  height: 50px;
  border-radius: 32.5px 32.178px 32.5px 32.5px;
  border-width: 0px;
  color: #fff;
  text-align: center;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: 20px;
  letter-spacing: 2px;

  @include small-screen {
    width: 100px;
    height: 30px;
  }

  @include x-small-screen {
    width: 86px;
    font-size: 12px;
    font-weight: 900;
  }

  @include xx-small-screen {
    width: 80px;
    font-size: 11px;
    font-weight: 800;
  }
}

.button {
  @extend %button;
  background-color: $color-neon;
  cursor: pointer;
}

.button__disabled {
  @extend %button;
  background-color: $color-light-grey;
}

.popup {
  position: absolute;
  top: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

div {
  > img {
    cursor: pointer;
  }
}

.mail__title {
  width: 280px;
  font-size: 35px;
  font-weight: 300;
  // background-color: red;

  @include x-small-screen {
    transform: scale(0.85);
  }

  @include xx-small-screen {
    transform: scale(0.7);
  }
}

.mail__subtitle {
  width: 260px;

  @include x-small-screen {
    transform: scale(0.9);
  }

  @include xx-small-screen {
    transform: scale(0.8);
  }
}

.text__length {
  @include small-screen {
    font-size: 14px;
  }
}
