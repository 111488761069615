@import "../../styles/variables";
@import "../../styles/mixins";

.header__style {
  position: relative;
  padding-bottom: 50px;

  @include x-large-screen {
    padding-bottom: 40px;
  }

  @include large-screen {
    padding-bottom: 30px;
  }

  @include medium-screen {
    padding-bottom: 20px;
  }
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
}

%ellipse-style {
  height: 1000px;
  flex-shrink: 0;
  border-radius: 500px;
  background: $color-light-grey;
  box-shadow: -20px 0px 21px 0px rgba(0, 0, 0, 0.1) inset,
    -6px 0px 18px 2px rgba(0, 0, 0, 0.41) inset,
    -10px 10px 15px 0px rgba(0, 0, 0, 0.25), 10px -10px 15px 0px #fff;
  position: absolute;
  transition: width 1s, left 1s, top 1s, fill 1s;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 2;

  @include x-large-screen {
    transform: scale(0.75);
  }

  @include large-screen {
    transform: scale(0.48);
  }

  @include medium-screen {
    transform: scale(0.45);
  }
}

.ellipse {
  @extend %ellipse-style;
  width: 332px;
  left: calc(50% - (332px / 2));
  top: -200px;

  @include x-large-screen {
    top: -340px;
  }
}

.ellipse__loaded {
  @extend %ellipse-style;
  width: 217px;
  left: calc(50% - (217px / 2));
  top: -680px;

  @include x-large-screen {
    top: -650px;
  }

  @include large-screen {
    top: -600px;
  }

  @include medium-screen {
    top: -605px;
  }
}

%box-style {
  opacity: 0.5;
  background: $header-color;
  box-shadow: -52.719px 0px 55.355px 0px rgba(0, 0, 0, 0.1) inset,
    -15.816px 0px 47.447px 5.272px rgba(0, 0, 0, 0.41) inset,
    -26.359px 26.359px 39.539px 0px rgba(0, 0, 0, 0.25),
    26.359px -26.359px 39.539px 0px #fff;
  position: relative;
  z-index: 1;
  transition: width 1s, height 1s;
}

.box {
  @extend %box-style;
  width: 100%;
  height: 1200px;
}

.box__first__load {
  @extend %box-style;
  width: 80%;
  height: 1200px;
}

.box__second__load {
  @extend %box-style;
  width: 975px;
  height: 700px;

  @include x-large-screen {
    width: 780px;
    height: 480px;
  }

  @include large-screen {
    width: 560px;
    height: 320px;
  }

  @include medium-screen {
    width: 380px;
    height: 245px;
  }
}

%shape-style {
  flex-shrink: 0;
  position: absolute;
  transition: left 1s, top 1s, transform 1s;
  display: flex;
  justify-content: center;
  align-items: center;
  // background-color: red;
}

.shape {
  @extend %shape-style;
  top: 700px;
  width: 238px;
  height: 238px;
}

.shape__loaded {
  @extend %shape-style;
  top: 820px;
  width: 150px;
  height: 150px;
}

%rectangle-style {
  flex-shrink: 0;
  position: absolute;
  transition: left 1s, top 1s, transform 1s, background-color 1s;
}

.rectangle {
  @extend %rectangle-style;
  width: 30px;
  height: 300px;
  transform: none;
  background-color: white;
}

.rectangle__loaded {
  @extend %rectangle-style;
  width: 15px;
  height: 165px;
  transform: rotate(90deg);
  background-color: #c3d400;
}

%logo-style {
  position: absolute;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 1s;
  // background-color: green;
}

.logo {
  @extend %logo-style;
  gap: 2vh;
  top: 480px;

  @include x-large-screen {
    top: 270px;
  }
  @include large-screen {
    top: 120px;
  }
  @include medium-screen {
    top: 70px;
  }
}

.logo__loaded {
  @extend %logo-style;
  gap: 4vh;
  top: 360px;

  @include x-large-screen {
    gap: 2.8vh;
    top: 230px;
    transform: scale(0.85);
  }

  @include large-screen {
    gap: 1.8vh;
    top: 110px;
    transform: scale(0.65);
  }

  @include medium-screen {
    gap: 0.1vh;
    top: 85px;
    transform: scale(0.62);
  }
}

%logo-text-style {
  height: 100.81px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  transition: all 1s;
  transform: scale(1.2);

  @include x-large-screen {
    transform: scale(1);
  }

  @include large-screen {
    transform: scale(0.8);
  }

  @include medium-screen {
    transform: scale(0.6);
  }
}

.logo__text {
  @extend %logo-text-style;
  width: 100px;
}

.logo__text__first__change {
  @extend %logo-text-style;
  width: 700px;
  transition: all width 500ms ease-out;
}

.logo__text__second__change {
  @extend %logo-text-style;
  width: 450px;
}

.description {
  color: $color-grey;
  text-align: center;
  font-family: Raleway;
  font-size: 28.8px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.4px;
  letter-spacing: 20px;

  @include x-large-screen {
    transform: scale(0.86);
  }

  @include large-screen {
    transform: scale(0.68);
  }

  @include medium-screen {
    transform: scale(0.52);
    letter-spacing: 17px;
  }
}

.arrow {
  width: 115.346px;
  height: 57.673px;
  flex-shrink: 0;
  fill: #c3d400;

  @include x-large-screen {
    transform: scale(0.86);
  }

  @include large-screen {
    transform: scale(0.64);
  }

  @include medium-screen {
    transform: scale(0.48);
  }
}

%choose-style {
  position: absolute;
  text-align: center;
  font-family: Raleway;
  font-style: normal;
  font-weight: 300;
  transition: font-size 1s, color 1s, line-height 1s;
}

.choose {
  @extend %choose-style;
  top: 550px;
  color: #5f5f5f;
  font-size: 48px;
  line-height: 34px;
}

.choose__second__load {
  @extend %choose-style;
  top: 750px;
  color: rgba(95, 95, 95, 0.5);
  font-size: 28.8px;
  line-height: 20.4px;
}

.choose__thirth__load {
  @extend %choose-style;
  top: 750px;
  color: transparent;
  font-size: 28.8px;
  line-height: 20.4px;

  @include medium-screen {
    top: 770px;
  }
}

.languages {
  position: absolute;
  width: 95px;
  height: 36.563px;
  background-color: $header-color;
  // background-color: red;
  color: #fff;
  top: 745px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 23.766px 23.53px 23.766px 23.766px;
  z-index: 10;
  transition: opacity 3s linear;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 1px;
  border-width: 0px;

  &:hover {
    background-color: $color-neon;
    cursor: pointer;
  }

  @include medium-screen {
    top: 765px;
  }
}
