@mixin x-large-screen() {
  @media only screen and (max-width: 1920px) {
    @content;
  }
}

@mixin large-screen() {
  @media only screen and (max-width: 1280px) {
    @content;
  }
}

@mixin medium-screen() {
  @media only screen and (max-width: 720px) {
    @content;
  }
}

@mixin small-screen() {
  @media only screen and (max-width: 480px) {
    @content;
  }
}

@mixin x-small-screen() {
  @media only screen and (max-width: 400px) {
    @content;
  }
}

@mixin xx-small-screen() {
  @media only screen and (max-width: 350px) {
    @content;
  }
}

@mixin center-content($type: xy) {
  position: absolute;
  left: 50%;

  @if $type == xy {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  } @else if $type == x {
    left: 50%;
    transform: translateX(-50%);
  } @else if $type == y {
    top: 50%;
    transform: translateY(-50%);
  }
}

@mixin box-hover($width, $height, $multiplier) {
  width: calc($width * $multiplier);
  height: calc($height * $multiplier);
  transition: all 300ms linear;
  z-index: 10;
}

@mixin text-mobile {
  color: var(--majabo-dark-gray);
  text-align: center;
  font-family: "Raleway", sans-serif;
  font-size: 20px;
  font-style: normal;
  line-height: 35px;

  @include x-small-screen {
    font-size: 18px;
    line-height: 30px;
  }

  @include xx-small-screen {
    font-size: 16px;
    line-height: 26px;
  }
}
