@import "../../styles/variables";
@import "../../styles/functions";
@import "../../styles/mixins";

$circle-width: 202px;
$ellipse-width: 137px;
$svg1-width: 78.749px;
$distance1: distance-in-ellipse($ellipse-width, $svg1-width);
$svg2-width: 78.749px;
$distance2: distance-in-ellipse($circle-width, $svg2-width);
$svg3-width: 68.847px;
$distance3: distance-in-ellipse($ellipse-width, $svg3-width);
$title-move: 210px;
$text-move: 55px;
$extended-ellipse-width: 370px;

%move-circle-title {
  .circle__title {
    top: $title-move;
  }

  .circle__title2 {
    bottom: 220px;
  }

  .circle__title3 {
    top: $title-move;
  }
}

%move-circle-text {
  .circle__text__content {
    bottom: $text-move;
  }

  .circle__text__content2 {
    top: $text-move;
  }
}

%text {
  color: $color-grey;
  text-align: center;
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.first__part {
  margin-bottom: -160px;

  @include x-large-screen {
    margin-bottom: -110px;
  }

  @include large-screen {
    margin-bottom: -80px;
  }

  @include medium-screen {
    margin-bottom: -50px;
  }
}

.content {
  width: 1293px;
  height: 650px;
  left: 50%;
  transform: translateX(-50%);
  position: relative;
  z-index: 1;
  margin-top: 100px;
  margin-bottom: 50px;
  overflow: hidden;
  // background-color: green;

  @include x-large-screen {
    height: 580px;
    width: 1034px;
  }

  @include large-screen {
    height: 400px;
    width: 756px;
  }

  @include medium-screen {
    height: 280px;
    // width: 500px;
  }
}

.content__box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 180px;
  // background-color: blue;
  align-items: center;

  @include x-large-screen {
    gap: 120px;
  }

  @include large-screen {
    gap: 20px;
  }

  @include medium-screen {
  }
}

.part {
  margin-top: -50px;
  width: 255px;
  height: 438px;
  // background-color: red;

  @include x-large-screen {
    transform: scale(0.85);
  }

  @include large-screen {
    transform: scale(0.6);
  }

  @include medium-screen {
    transform: scale(0.45);
  }
}

.part2 {
  margin-top: 40px;
  width: 202px;
  height: 538px;
  // background-color: red;
  display: flex;
  flex-direction: column;

  @include x-large-screen {
    transform: scale(0.85);
  }

  @include large-screen {
    transform: scale(0.6);
  }

  @include medium-screen {
    transform: scale(0.45);
    margin-left: -80px;
    margin-right: -80px;
  }
}

.group {
  width: 100%;
  height: 343px;
  //   background-color: yellow;
  position: relative;

  &:hover > .circle {
    height: $extended-ellipse-width;
  }

  &:hover {
    @extend %move-circle-title;
    @extend %move-circle-text;
  }
}

.frame {
  width: 100%;
  height: 95px;
}

.frame2 {
  width: 100%;
  height: 145px;
}

.circle {
  width: $circle-width;
  height: $circle-width;
  flex-shrink: 0;
  border-radius: 100px;
  background: $color-neon;
  z-index: 4;
  cursor: pointer;
  transition: height $transition ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.circle__title {
  width: 44%;
  color: #fff;
  text-align: center;
  font-style: normal;
  font-weight: 900;
  transition: top $transition ease;
  position: absolute;
  z-index: 10;
  left: 95px;
  top: 190px;
}

.circle__title2 {
  width: 60%;
  color: #fff;
  text-align: center;
  font-style: normal;
  font-weight: 900;
  transition: bottom $transition ease;
  position: absolute;
  z-index: 10;
  left: 55px;
  bottom: 190px;
  @include center-content(x);
}

.circle__title3 {
  width: 44%;
  color: #fff;
  text-align: center;
  font-style: normal;
  font-weight: 900;
  transition: top $transition ease;
  position: absolute;
  z-index: 10;
  right: 95px;
  top: 190px;
}

.ellipse {
  width: $ellipse-width;
  height: 245px;
  flex-shrink: 0;

  border-radius: 764.98px 764.98px 764.977px 764.977px;
  opacity: 0.6;
  background: $color-light-grey;
  mix-blend-mode: multiply;
  box-shadow: -30.599px 0px 32.129px 0px rgba(0, 0, 0, 0.1) inset,
    -9.18px 0px 27.539px 3.06px rgba(0, 0, 0, 0.41) inset,
    -15.3px 15.3px 22.949px 0px rgba(0, 0, 0, 0.25),
    15.3px -15.3px 22.949px 0px #fff;
  z-index: 5;
}

.svg1 {
  width: $svg1-width;
  height: 68.905px;
  flex-shrink: 0;
  //   background-color: aqua;
  position: absolute;
  left: $distance1;
  top: $distance1 + 5px;
}

.svg2 {
  width: $svg2-width;
  height: 68.905px;
  flex-shrink: 0;
  //   background-color: aqua;
  position: absolute;
  left: $distance2;
  bottom: $distance2 - 20px;
}

.svg3 {
  width: $svg3-width;
  height: 68.905px;
  flex-shrink: 0;
  //   background-color: aqua;
  position: absolute;
  right: $distance3;
  top: $distance3;
}

.circle__text__content {
  position: absolute;
  bottom: -370px;
  right: 0;
  width: $circle-width;
  // background-color: pink;
  color: $color-grey;
  transition: bottom $transition ease, color 500ms ease-out;
  @extend %text;
}

.circle__text__content2 {
  position: absolute;
  top: -370px;
  right: 0;
  width: $circle-width;
  // background-color: pink;
  color: $color-grey;
  transition: top $transition ease, color 500ms ease-out;
  @extend %text;
}

.circle__text {
  text-align: center;
  padding-left: 25%;
  padding-right: 25%;
}
