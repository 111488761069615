@import "../../styles/variables";
@import "../../styles/mixins";

footer {
  width: 100%;
  height: 524px;
  display: flex;
  align-items: end;
  justify-content: center;
  // background-color: red;
  position: absolute;

  @include x-large-screen {
    height: 380px;
  }

  @include large-screen {
    height: 340px;
  }

  @include medium-screen {
    height: 300px;
  }
}

.box {
  height: 500px;
  width: 975px;
  opacity: 0.5;
  background: $header-color;
  box-shadow: -52.719px 0px 55.355px 0px rgba(0, 0, 0, 0.1) inset,
    -15.816px 0px 47.447px 5.272px rgba(0, 0, 0, 0.41) inset,
    -26.359px 26.359px 39.539px 0px rgba(0, 0, 0, 0.25),
    26.359px -26.359px 39.539px 0px #fff;

  transition: all 500ms linear;

  @include x-large-screen {
    width: 780px;
  }

  @include large-screen {
    width: 458px;
  }

  @include medium-screen {
    width: 350px;
  }
}

.content {
  position: absolute;
  height: 500px;
  width: 50%;
  margin-bottom: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-clip: padding-box;

  @include x-large-screen {
    height: 330px;
  }
}

.social__media {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  gap: 40px;
  font-size: 50px;
  // background-color: red;

  > img {
    width: 74.3px;
    height: 74.3px;
  }

  @include x-large-screen {
    margin-top: 40px;

    > img {
      width: 59px;
      height: 59px;
    }
  }

  @include large-screen {
    margin-top: 40px;

    > img {
      width: 41.6px;
      height: 41.6px;
    }
  }
}

.logo {
  position: absolute;
  transition: transform 500ms linear;
  // background-color: red;
  bottom: 100px;

  @include x-large-screen {
    bottom: 80px;
    transform: scale(0.95);
  }

  @include large-screen {
    bottom: 60px;
    transform: scale(0.8);
  }

  @include medium-screen {
    bottom: 45px;
    transform: scale(0.7);
  }
}

.rotated {
  transform: rotate(180deg);

  @include x-large-screen {
    transform: rotate(180deg) scale(0.95);
  }

  @include large-screen {
    transform: rotate(180deg) scale(0.8);
  }

  @include medium-screen {
    transform: rotate(180deg) scale(0.7);
  }
}

.small__text {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  bottom: 50px;

  > p {
    margin: 0;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  @include x-large-screen {
    bottom: 40px;
    > p {
      font-size: 9.8px;
      line-height: 16px;
    }
  }

  @include large-screen {
    bottom: 30px;
    > p {
      font-size: 6.72px;
      line-height: 11.2px;
    }
  }

  @include medium-screen {
    bottom: 20px;
  }
}

.email {
  width: 700px;
  margin-left: 15%;
  margin-right: 15%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  opacity: 0;
  transition: opacity 500ms ease;

  @include x-large-screen {
    width: 560px;
    gap: 4px;
  }

  @include large-screen {
    width: 329px;
    gap: 2px;
  }

  @include medium-screen {
    width: 251px;
    gap: 0px;
  }
}

.visibleDiv {
  opacity: 1;
}

.field {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  width: 703px;
  gap: 10px;
  // background-color: red;

  @include x-large-screen {
    width: 563px;
  }

  @include large-screen {
    width: 332px;
  }

  @include medium-screen {
    width: 254px;
  }
}

.label {
  color: $color-grey;
  text-align: center;
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px; /* 200% */
  width: 100px;
  text-align: start;

  @include x-large-screen {
    width: 80px;
    font-size: 19px;
    line-height: 36px;
  }
  @include large-screen {
    width: 60px;
    font-size: 15px;
    line-height: 30px;
  }
  @include medium-screen {
    width: 40px;
    font-size: 12px;
    line-height: 24px;
  }
}

.input {
  border-width: 0px;
  text-align: start;
  flex: 1;
  height: 20px;
  font-size: 16px;

  @include x-large-screen {
    height: 18.5px;
    font-size: 15px;
  }
  @include large-screen {
    height: 16px;
    font-size: 12.5px;
  }
  @include medium-screen {
    height: 14px;
    font-size: 11px;
  }
}

.text__area {
  margin-top: 10px;
  width: 100%;
  border-width: 0px;
  height: 200px;
  text-align: left;
  resize: none;
  overflow-y: auto;
  font-size: 16px;

  @include x-large-screen {
    height: 155px;
    font-size: 15px;
    margin-top: 8px;
  }
  @include large-screen {
    height: 120px;
    font-size: 12.5px;
    margin-top: 6px;
  }
  @include medium-screen {
    height: 100px;
    font-size: 11px;
    margin-top: 4px;
  }
}

.buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
}

%button {
  width: 200px;
  height: 50px;
  border-radius: 32.5px 32.5px 32.5px 32.5px;
  border-width: 0px;
  color: #fff;
  text-align: center;
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
  line-height: 20px;
  letter-spacing: 4px;

  @include x-large-screen {
    width: 160px;
    height: 40px;
    font-size: 16px;
  }
  @include large-screen {
    width: 120px;
    height: 30px;
    font-size: 13px;
    letter-spacing: 3px;
  }
  @include medium-screen {
    width: 90px;
    height: 20px;
    font-size: 9px;
    letter-spacing: 2px;
  }
}

.button {
  @extend %button;
  background-color: $color-neon;
  cursor: pointer;
}

.button__disabled {
  @extend %button;
  background-color: $color-light-grey;
}

.popup {
  position: absolute;
  top: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

div {
  > img {
    cursor: pointer;
  }
}

.titles__style {
  margin-top: 25px;
}

.titles__email__style {
  margin-top: 30px;
}

.text__count {
  margin-top: -10px;
  font-size: 18px;

  @include x-large-screen {
    font-size: 16px;
  }
  @include large-screen {
    font-size: 13px;
  }
  @include medium-screen {
    font-size: 11px;
  }
}
