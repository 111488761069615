@import "../styles/variables";
@import "../styles/mixins";

.box {
  width: 100%;
  height: 200px;
  // background-color: red;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;

  @include x-large-screen {
    height: 170px;
    margin-bottom: 40px;
  }

  @include large-screen {
    height: 140px;
    margin-bottom: 20px;
  }

  @include medium-screen {
    height: 100px;
    margin-bottom: 10px;
  }
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 100px;

  @include x-large-screen {
    gap: 50px;
  }

  @include large-screen {
    gap: 0px;
  }
}

.text {
  font-family: Raleway;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 2.4px;
  color: $color-grey;

  @include x-large-screen {
    font-size: 19.2px;
    line-height: 32px;
    letter-spacing: 2px;
  }

  @include large-screen {
    font-size: 12.74px;
    line-height: 21.2px;
    letter-spacing: 1.5px;
    margin-left: 20px;
    margin-right: 20px;
  }

  @include medium-screen {
    font-size: 10px;
    line-height: 15px;
    letter-spacing: 1px;
  }
}

.letter {
  margin-right: 0.5px;
}

.color__change {
  animation: colorChange 1250ms forwards;
}

@keyframes colorChange {
  0%,
  100% {
    color: $color-grey;
  }

  50% {
    color: $color-neon;
  }
}
