@import "../styles/variables";
@import "../styles/mixins";

.testimonials {
  margin-bottom: 200px;
  margin-top: 80px;

  @include large-screen {
    margin-bottom: 150px;
  }

  @include medium-screen {
    margin-bottom: 100px;
  }
}

.content {
  width: 100%;
  height: 550px;
  position: relative;
  z-index: 1;
  overflow-x: auto;

  // background-color: green;

  @include small-screen {
    height: 450px;
  }

  @include x-small-screen {
    height: 420px;
  }

  @include xx-small-screen {
    height: 380px;
  }
}

.content__box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  // background-color: blue;
  align-items: center;

  @include x-large-screen {
    width: 1800px;
  }

  @include large-screen {
    width: 1700px;
  }

  @include medium-screen {
    width: 1600px;
  }

  @include small-screen {
    width: 1400px;
  }

  @include x-small-screen {
    width: 1300px;
  }

  @include xx-small-screen {
    width: 1000px;
  }
}

.box {
  width: 400px;
  height: 367px;
  // background-color: aqua;
  position: relative;
  margin-left: -10px;
  margin-right: -10px;

  @include x-large-screen {
    transform: scale(0.96);
    margin-left: -14px;
    margin-right: -14px;
  }

  @include large-screen {
    transform: scale(0.92);
    margin-left: -20px;
    margin-right: -20px;
  }

  @include medium-screen {
    transform: scale(0.87);
    margin-left: -25px;
    margin-right: -25px;
  }

  @include small-screen {
    transform: scale(0.85);
    margin-left: -30px;
    margin-right: -30px;
  }

  @include x-small-screen {
    transform: scale(0.78);
    margin-left: -45px;
    margin-right: -45px;
  }

  @include xx-small-screen {
    transform: scale(0.65);
    margin-left: -68px;
    margin-right: -68px;
  }
}

%text-field {
  width: 100%;
  height: 202px;
  flex-shrink: 0;
  border-radius: 101px 100px 101px 101px;
  background: $color-neon;
  position: absolute;
}

.text__field1 {
  @extend %text-field;
  bottom: 0;
}

.text__field2 {
  @extend %text-field;
  top: 0;
}

%ellipse {
  position: absolute;
  width: 137px;
  height: 245px;
  flex-shrink: 0;

  border-radius: 764.98px 764.98px 764.977px 764.977px;
  opacity: 0.6;
  background: $color-light-grey;
  mix-blend-mode: multiply;
  box-shadow: -30.599px 0px 32.129px 0px rgba(0, 0, 0, 0.1) inset,
    -9.18px 0px 27.539px 3.06px rgba(0, 0, 0, 0.41) inset,
    -15.3px 15.3px 22.949px 0px rgba(0, 0, 0, 0.25),
    15.3px -15.3px 22.949px 0px #fff;

  @include center-content(x);
}

.ellipse1 {
  @extend %ellipse;
  @include center-content(x);
}
.ellipse2 {
  @extend %ellipse;
  bottom: 0;
}

%title {
  color: #fff;
  font-family: Raleway;
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
  line-height: 24px; /* 100% */
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @include small-screen {
  }
}

.title1 {
  @extend %title;
  bottom: 160px;
}
.title2 {
  @extend %title;
  top: 130px;
}

%sub-title {
  color: #fff;
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  position: absolute;
  width: 300px;
  @include center-content(x);
  text-align: center;
}

.sub__title1 {
  @extend %sub-title;
  bottom: 130px;
}
.sub__title2 {
  @extend %sub-title;
  top: 160px;
}

%text-box {
  width: 330px;
  @include center-content(x);
  // width: 90%;
  // margin-left: 5%;
  // margin-right: 5%;
}

.text__box1 {
  @extend %text-box;
  bottom: 20px;
}
.text__box2 {
  @extend %text-box;
  top: 20px;
}

%image {
  position: absolute;
  width: 78px;
  height: 124px;
  flex-shrink: 0;
  background-color: red;
  background: lightgray -9px 0px / 158.974% 100% no-repeat;
  @include center-content(x);
}

.image1 {
  @extend %image;
  top: 28px;
  border-radius: 50px 50px 0px 0px;
}

.image2 {
  @extend %image;
  bottom: 28px;
  border-radius: 0px 0px 50px 50px;
}
