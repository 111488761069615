@import "../../styles/variables";
@import "../../styles/functions";
@import "../../styles/mixins";

$circle-width: 145px;
$ellipse-width: 88px;
$svg1-width: 50px;
$distance1: distance-in-ellipse($ellipse-width, $svg1-width);
$svg2-width: 50px;
$distance2: distance-in-ellipse($circle-width, $svg2-width);
$svg3-width: 44px;
$distance3: distance-in-ellipse($ellipse-width, $svg3-width);
$text-move: 55px;
$extended-ellipse-width: 370px;

%text {
  color: $color-grey;
  text-align: center;
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.content {
  width: 100%;
  height: 530px;
  position: relative;
  z-index: 1;
  padding-top: 100px;
  margin-bottom: 50px;
  overflow: hidden;
  // background-color: green;
}

.content__box {
  height: 100%;
  display: flex;
  flex-direction: row;
  // justify-content: center;
  gap: 60px;
  // background-color: blue;
  align-items: center;
  overflow-x: auto;

  @include x-small-screen {
    gap: 48px;
  }

  @include xx-small-screen {
    gap: 20px;
  }
}

.part {
  margin-top: -50px;
  width: 175px;
  height: 438px;
  position: relative;

  @include x-small-screen {
    transform: scale(0.9);
  }

  @include xx-small-screen {
    transform: scale(0.8);
  }
}

.part2 {
  margin-top: -170px;
  width: 145px;
  height: 538px;
  position: relative;

  @include x-small-screen {
    transform: scale(0.9);
  }

  @include xx-small-screen {
    transform: scale(0.8);
  }
}

.part3 {
  margin-top: -50px;
  width: 175px;
  height: 438px;
  position: relative;

  @include x-small-screen {
    transform: scale(0.9);
  }

  @include xx-small-screen {
    transform: scale(0.8);
  }
}

.circle {
  width: $circle-width;
  height: 250px;
  flex-shrink: 0;
  border-radius: 100px;
  background: $color-neon;
  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.circle__title {
  width: 80%;
  color: #fff;
  text-align: center;
  font-style: normal;
  font-weight: 900;
  position: absolute;
  z-index: 10;
  @include center-content(x);
  top: 30px;
  font-size: 20px;
  // background-color: blue;
}

.ellipse {
  width: $ellipse-width;
  height: 158px;
  flex-shrink: 0;

  border-radius: 764.98px 764.98px 764.977px 764.977px;
  opacity: 0.6;
  background: $color-light-grey;
  mix-blend-mode: multiply;
  box-shadow: -30.599px 0px 32.129px 0px rgba(0, 0, 0, 0.1) inset,
    -9.18px 0px 27.539px 3.06px rgba(0, 0, 0, 0.41) inset,
    -15.3px 15.3px 22.949px 0px rgba(0, 0, 0, 0.25),
    15.3px -15.3px 22.949px 0px #fff;
  z-index: 5;
}

.svg1 {
  width: $svg1-width;
  height: 44px;
  flex-shrink: 0;
  //   background-color: aqua;
  position: relative;
  left: $distance1;
  top: $distance1 + 5px;
}

.svg2 {
  width: $svg2-width;
  height: 44px;
  flex-shrink: 0;
  //   background-color: aqua;
  position: relative;
  left: $distance2;
  top: 450px;
}

.svg3 {
  width: $svg3-width;
  height: 44px;
  flex-shrink: 0;
  //   background-color: aqua;
  position: relative;
  right: -78px;
  top: $distance3;
}

.circle__text__content {
  // position: absolute;
  right: 0;
  width: $circle-width;
  // background-color: pink;
  @extend %text;
}

.circle__text__content2 {
  position: absolute;
  top: -370px;
  right: 0;
  width: $circle-width;
  // background-color: pink;
  color: $color-grey;
  transition: top $transition ease, color 500ms ease-out;
  @extend %text;
}

.circle__text {
  text-align: center;
  margin-top: 40px;
  font-size: 15px;
  line-height: 20px;
}

.title {
  @include small-screen {
    width: 230px;
  }
  @include x-small-screen {
    width: 220px;
  }
  @include xx-small-screen {
    width: 170px;
  }
}

.subtitle {
  @include small-screen {
    font-size: 20px;
  }
  @include xx-small-screen {
    font-size: 14px;
  }
}

.text {
  padding-top: 50px;
  margin-bottom: -30px;
  @include text-mobile;

  @include xx-small-screen {
    padding-top: 30px;
    margin-bottom: -80px;
  }
}
